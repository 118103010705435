import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Menu, X, Instagram } from "lucide-react";
import logo_black from "assets/logo_black.png";
import naver_icon from "assets/naver_icon.png";
import { useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  color: #fff;
  transition: background-color 0.3s ease;
  position: ${(props) =>
    props.isScrolled ? "sticky" : "static"}; /* 스크롤 시 고정 */
  top: 0; /* sticky가 적용될 위치 */
  z-index: 100; /* 다른 요소보다 앞에 오도록 설정 */
  height: 70px;
`;

const LeftCol = styled.div`
  width: 387px;
  display: flex;
  align-items: center;
`;

const CenterCol = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const RightCol = styled.div`
  width: 387px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  padding: 10px;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const Logo = styled.img`
  height: 50px;
`;

const LogoContainer = styled.div`
  position: relative;
  text-align: center;
  padding: 20px;
`;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
  transition: transform 0.3s ease-in-out;
  z-index: 1000;

  display: flex;
  flex-direction: column;
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  padding: 10px;
  transition: opacity 0.2s;
  padding: 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const SidebarLogo = styled.img`
  height: 40px;
  padding: 20px;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 20px;
`;

const MenuItem = styled.li`
  margin-bottom: 50px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: auto; /* SocialIcons를 하단으로 내림 */
  padding: 20px;
`;
const SocialIcon = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 24px;

  &:hover {
    opacity: 0.8;
  }
`;
export default function Header() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [animation, setAnimation] = useState(""); // 애니메이션 상태 추가

  const navigate = useNavigate();
  const location = useLocation();
  const handleMenuToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClose = () => {
    setIsSidebarOpen(false);
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleScroll = useCallback(
    debounce(() => {
      const scrolled = window.scrollY > 180;
      const atTop = window.scrollY === 0;

      if (scrolled && !isScrolled) {
        setIsScrolled(true);
        setAnimation("animate__fadeIn");
      } else if (atTop && isScrolled) {
        setIsScrolled(false);
        setAnimation("animate__fadeOut");
      }
    }, 100),
    [isScrolled]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const handleMenuClick = (path) => {
    if (location.pathname === path) {
      navigate(0); // 현재 경로일 때 페이지 새로 고침
    } else {
      navigate(path); // 다른 경로로 이동
    }
    setIsSidebarOpen(false); // 사이드바 닫기
  };
  return (
    <>
      <HeaderContainer isScrolled={isScrolled}>
        {/* 메뉴 */}
        <LeftCol>
          <MenuButton onClick={handleMenuToggle}>
            <Menu size={24} />
          </MenuButton>
        </LeftCol>
        {isScrolled ? (
          <CenterCol
            className={`animate__animated animate__faster ${animation}`}
          >
            {/* 로고 */}
            <LogoContainer>
              <a href="/">
                <Logo src={logo_black} alt="Company Logo" />
              </a>
            </LogoContainer>
          </CenterCol>
        ) : null}
        <RightCol
        // className={`animate__animated animate__faster ${animation}`}
        />
      </HeaderContainer>

      <Overlay isOpen={isSidebarOpen} onClick={handleClose} />
      <Sidebar isOpen={isSidebarOpen}>
        <SidebarHeader>
          <Link to="/">
            <SidebarLogo
              src={logo_black}
              alt="Company Logo"
              onClick={() => handleMenuClick("/")}
            />
          </Link>
          <CloseButton onClick={handleClose}>
            <X size={24} />
          </CloseButton>
        </SidebarHeader>
        <MenuList>
          <MenuItem>
            <Link
              to="/"
              style={{ color: "#000", textDecoration: "none" }}
              onClick={() => handleMenuClick("/")}
            >
              HOME
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/weare"
              style={{ color: "#000", textDecoration: "none" }}
              onClick={() => handleMenuClick("/weare")}
            >
              WE ARE
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/portfolio"
              style={{ color: "#000", textDecoration: "none" }}
              onClick={() => handleMenuClick("/portfolio")}
            >
              PORTFOLIO
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link
              to="https://www.google.com/"
              target="_blank"
              style={{ color: "#000", textDecoration: "none" }}
              onClick={() => setIsSidebarOpen(false)} // 사이드바만 닫음
            >
              CAREER
            </Link>
          </MenuItem> */}
          <MenuItem>
            <Link
              to="/Proposal"
              style={{ color: "#000", textDecoration: "none" }}
              onClick={() => handleMenuClick("/Proposal")}
            >
              PROPOSAL
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link
              to="https://www.google.com/"
              target="_blank"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              ADMIN
            </Link>
          </MenuItem> */}
        </MenuList>
        <SocialIcons>
          <SocialIcon
            href="https://www.instagram.com/421.soho/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram size={28} color="#000">
              <Link
                to="https://www.instagram.com/421.soho/"
                target="_blank"
                style={{ color: "#fff", textDecoration: "none" }}
              ></Link>
            </Instagram>
          </SocialIcon>
          <SocialIcon>
            <Link
              to="https://naver.me/F8bBG9eP"
              target="_blank"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              <img src={naver_icon} alt="Naver" style={{ height: "28px" }} />
            </Link>
          </SocialIcon>
        </SocialIcons>
      </Sidebar>
    </>
  );
}
