"use client";

import React from "react";
import styled from "styled-components";
const Section1 = styled.section`
  padding: 80px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #53503f;
`;

const Description = styled.p`
  max-width: 850px;
  margin: 0 auto 40px;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 300;
`;

const TextArea = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: center;
`;

export default function WeAre() {
  return (
    <Section1>
      {/* <HeaderImage src={ex1} alt="picture" /> */}
      <TextArea>
        {/* <SliderTitle>BRAND BEYOND EATERIES</SliderTitle> */}
        <Description>
          421Co.는 2023년 팝오버 베이크하우스 421소호 런칭 이후 Roi_on_421 등을
          런칭한 Food & Lifestyle 전문 기업입니다. <br />
          <br />
          <br />
          421Co.는 새로운 트렌드를 리딩하며 사랑받는 Lifestyle 브랜드 기업으로의
          성장을 목표합니다. ’일상 속 여유‘를 목표로 한 Food & Lifestyle
          브랜드를 선보입니다.
        </Description>
      </TextArea>
    </Section1>
  );
}
