import React, { useState } from "react";
import { Typography } from "components/atom/Typography";
import { LoadingProgress } from "components/atom/LoadingProgress";
import { PageTitle } from "components/molecules/PageTitle";
import { TextFieldWithButton } from "components/atom/TextFieldWithButton";
import styled from "styled-components";
import logo from "assets/421SOHO_FINAL_2.png";
import { functions } from "../../firebaseConfig";
import { httpsCallable } from "firebase/functions";

const CentralizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 760px;
  margin: 0 auto;
`;

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  padding: 20px;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const Logo = styled.img`
  padding: 40px 0 0 0;
  height: 48px;
  width: 48px;
`;

const StyledTitle = styled.div`
  color: #0037FF;
  font-family: "Fira Code";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 40px 0;
  display: flex;
  justify-content: center;
`;

const GeneratorContainer = styled.div`
  display: flex;
  justify-content: center; 
`;

const ImageDisplayArea = styled.div`
  justify-content: center; 
  margin-top: 20px; 
  gap: 40px;
  width: 100%;
`;

const PlaceholderBox = styled.div`
  display: flex;
  width: 760px;
  height: 480px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid #C5D1FF;
  background: #FFF;
  margin: 20px 0;
`;


const StyledPlaceholderText = styled.div`
  color: #999;
  font-family: "Fira Code";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

const ImageDisplay = styled.img`
  width: 670px;
  height: 448px;
`;

const thumbnailsPerRow = 5; // Define the number of thumbnails per row
const thumbnailWidth = 159; // Width of each thumbnail in pixels
const gap = 40; // Gap between thumbnails in pixels

const ImageListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${gap}px;
  margin-top: 48px;
  width: calc(${thumbnailWidth * thumbnailsPerRow}px + ${gap * (thumbnailsPerRow - 1)}px); /* Calculated total width */
`;

const ThumbnailImage = styled.img`
  width: 159px;
  height: 140px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.2s;
  border-radius: 12px;
  border: 1px solid #DDD;

  &:hover {
    transform: scale(1.1);
  }
`;

const LoadingText = styled.div`
  color: #0037FF;
  text-align: center;
  font-family: "Fira Code";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 8px; /* Optional: adjust spacing between LoadingProgress and text */
`;


const Generate = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [thumbnailImages, setThumbnailImages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGenerateClick = async () => {
    setLoading(true);
    setError(null);
  
    try {
      let prompt = inputValue || "Roi and Pip";
      prompt = prompt
        .replace(/Roi|ROI|roi/g, "{Roi}, a large white dog with a pear-shaped body and a simple round face with small dot-like eyes,")
        .replace(/Pip|PIP|pip/g, "{Pip}, the mischievous small black creature with pointy two ears and a devil-like tail,");
      prompt = 'YS Style, clean lines and a monochromatic palette focusing on expressive cartoon-like characters. ' + prompt.trim();
      console.log("Prompt :", prompt);
  
      const proxyToServer = httpsCallable(functions, "proxyToServer");
      const result = await proxyToServer({ prompt });
  
      setSelectedImage(result.data);
      setThumbnailImages((prevImages) => [...prevImages, result.data]);
    } catch (error) {
      setError("Image generation failed. Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };  

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <CentralizedContainer>
      <StyledApp>
        <Header>
          <Logo src={logo} alt="421 SOHO Logo" />
        </Header>
        <StyledTitle>AI IP GENERATOR</StyledTitle>
        <GeneratorContainer>
          <TextFieldWithButton
            id="uniqueID"
            placeholder="ENTER PROMPT HERE"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onButtonClick={handleGenerateClick}
          />
        </GeneratorContainer>
        <ImageDisplayArea>
          <PlaceholderBox>
            {loading ? (
              <>
                <LoadingProgress state="one-hundred-and-thirty-five" />
                <LoadingText>Loading...</LoadingText>
              </>
            ) : error ? (
              <StyledPlaceholderText>{error}</StyledPlaceholderText>
            ) : selectedImage ? (
              <ImageDisplay src={selectedImage} alt="Generated ROI" />
            ) : (
              <StyledPlaceholderText>
                WRITE PROMPT TO GENERATE A NEW IMAGE
              </StyledPlaceholderText>
            )}
          </PlaceholderBox>
          <ImageListContainer>
            {thumbnailImages.map((image, index) => (
              <ThumbnailImage
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => handleThumbnailClick(image)}
              />
            ))}
          </ImageListContainer>
        </ImageDisplayArea>
      </StyledApp>
    </CentralizedContainer>
  );
};

export default Generate;
