import React from "react";
import styled from "styled-components";
import roion1 from "assets/roion1.png";
import roion2 from "assets/roion2.png";
import roion3 from "assets/roion3.png";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import p2 from "assets/p2.png";
import SlideSection from "../../sections/SlideSection";
import { Instagram } from "lucide-react";
import { Link } from "react-router-dom";
const MainContainer = styled.div`
  background-color: #fff;
  color: #53503f;
`;

const Section = styled.section`
  padding: 2rem 2rem;
  padding-bottom: 7rem;
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
`;
const Section1 = styled.section`
  padding: 100px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  color: #53503f;
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;
const MainLogo = styled.img`
  max-width: 350px;
  margin-bottom: 30px;
`;
const SocialIcon = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
`;
export default function roion421() {
  const slidesData = [
    {
      image: roion1,
      caption: "",
      description: "",
    },
    {
      image: roion2,
      caption: "",
      description: "",
    },
    {
      image: roion3,
      caption: "",
      description: "",
    },
  ];
  return (
    <MainContainer>
      <div className="animate__animated animate__fadeIn">
        <Header />
        <Section1>
          <MainLogo src={p2} alt="Logo" />
        </Section1>
        <Section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SectionTitle>Roi_on_421</SectionTitle>
            <SocialIcon
              href="https://www.instagram.com/roi_on_421/  "
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram size={28} color="#000">
                <Link
                  to="https://www.instagram.com/roi_on_421/  "
                  target="_blank"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                ></Link>
              </Instagram>
            </SocialIcon>
          </div>
          <p
            style={{ maxWidth: "800px", margin: "0 auto", textAlign: "center" }}
          >
            ‘Roi_on_421(421번가 로이)’은 421Co.의 고유한 IP입니다. <br />
            <br />
            대표 IP인 로이(Roi)가 421 Broome으로 이사오면 만나게 되는 뉴욕
            사교계 명사 핍(Pip)과 함께하며 벌어지는 다양한 일상의 이야기를
            80-90년대 뉴욕 신문 삽화 스타일의 카툰으로 풀어냅니다.
          </p>
        </Section>
        <Section1>
          <SlideSection slides={slidesData} />
        </Section1>
        {/* <Section2>
          <HeaderImage src={roion1} alt="picture" />
        </Section2>
        <Section2>
          <HeaderImage src={roion2} alt="picture" />
        </Section2>
        <Section2>
          <HeaderImage src={roion3} alt="picture" />
        </Section2> */}
        <Footer />
      </div>
    </MainContainer>
  );
}
