import React from "react";
import styled from "styled-components";
import soho1 from "assets/soho1.png";
import soho2 from "assets/soho2.png";
import soho3 from "assets/soho3.png";
import soho4 from "assets/soho4.png";
import soho5 from "assets/soho5.png";
import logo_black from "assets/logo_black.png";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import p1 from "assets/p1.png";
import { Instagram } from "lucide-react";
import { Link } from "react-router-dom";
import SlideSection from "../../sections/SlideSection";
const MainContainer = styled.div`
  background-color: #fff;
  color: #53503f;
`;

const Section = styled.section`
  padding: 2rem 2rem;
  padding-bottom: 7rem;
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
`;
const Section1 = styled.section`
  padding: 100px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  color: #53503f;
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;
const MainLogo = styled.img`
  max-width: 150px;
  margin-bottom: 40px;
`;
const SocialIcon = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
`;
export default function soho() {
  const slidesData = [
    {
      image: soho1,
      caption: "",
      description: "",
    },
    {
      image: soho2,
      caption: "",
      description: "",
    },
    {
      image: soho3,
      caption: "",
      description: "",
    },
    {
      image: soho4,
      caption: "",
      description: "",
    },
    {
      image: soho5,
      caption: "",
      description: "",
    },
  ];
  return (
    <MainContainer>
      <div className="animate__animated animate__fadeIn">
        <Header />
        <Section1>
          <MainLogo src={p1} alt="Logo" />
        </Section1>
        <Section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SectionTitle>421 SoHo Bakehouse</SectionTitle>
            <SocialIcon
              href="https://www.instagram.com/421.soho/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram size={28} color="#000">
                <Link
                  to="https://www.instagram.com/421.soho/"
                  target="_blank"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                ></Link>
              </Instagram>
            </SocialIcon>
          </div>
          <p
            style={{ maxWidth: "800px", margin: "0 auto", textAlign: "center" }}
          >
            421소호는 뉴욕의 랜드마크 빌딩인 421 Broome Street의 각 층에 살고
            있는 다양한 문화의 입주자들의 집을 테마로 한 팀421의 브랜드
            플래그십스토어입니다. 첫 번째 스토어인 421소호 서촌은 세종대왕
            생가에 위치한 낡은 한옥 저택에 테마 건물의 붉은 벽돌 벽과 검은
            철기둥을 더하여 421 Broome Street의 1층 입구를 한국적으로 재해석한
            공간입니다.
            <br />
            <br /> 한국적인 고전미에 뉴욕의 낯섦이 더해진 421서촌에서는 ‘손 안의
            다이닝’을 컨셉으로 독창적인 팝오버 장르를 첫 선보입니다. 매일 아침
            다양한 고메 세이보리와 디저트 팝오버, 시그너처 샌드위치, 홈메이드
            스프 그리고 창의적인 음료를 정성껏 준비합니다.
          </p>
        </Section>
        <Section1>
          <SlideSection slides={slidesData} />
        </Section1>
        {/* <Section2>
          <HeaderImage src={soho2} alt="picture" />
        </Section2>
        <Section2>
          <HeaderImage src={soho3} alt="picture" />
        </Section2>
        <Section2>
          <HeaderImage src={soho4} alt="picture" />
        </Section2>
        <Section2>
          <HeaderImage src={soho5} alt="picture" />
        </Section2> */}
        <Footer />
      </div>
    </MainContainer>
  );
}
