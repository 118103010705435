"use client";

import React from "react";
import styled from "styled-components";
import ex2 from "assets/ex2.jpg";
import weare1 from "assets/weare1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Header from "../layout/header";
import Footer from "../layout/footer";
import WeAreSection from "../sections/WeAreSection";
import logo_black from "assets/logo_black.png";

const MainContainer = styled.div`
  background-color: #ffffff;
  color: #fff;
`;

const Section1 = styled.section`
  padding: 100px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #53503f;
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const SliderTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 500;
`;

const Description = styled.p`
  max-width: 850px;
  margin: 0 auto 40px;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 300;
`;

const FixedBackgroundSection = styled.div`
  background-image: url(${weare1});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.35);
    z-index: -1;
  }
`;

const ScrollContent = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
  margin: 20px;
`;

const HistorySection = styled.section`
  background-color: #fff;
  color: #53503f;
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 60px;
  font-weight: 500;
`;

const Timeline = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
`;

const TimelineItem = styled.div`
  margin-bottom: 80px;
  position: relative;
  padding-left: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  &:last-child::after {
    display: none;
  }
`;

const Year = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 20px;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const CircleIcon = styled.span`
  width: 20px;
  height: 20px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
`;

const EventList = styled.ul`
  list-style: none;
  padding: 0;
  margin-left: 40px;
`;

const Event = styled.li`
  margin-bottom: 12px;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 300;

  &::before {
    content: "•";
    margin-right: 10px;
  }
`;

const MainLogo = styled.img`
  max-width: 100px;
  margin-bottom: 40px;
`;

export default function WeAre() {
  const historyData = [
    {
      year: "2024",
      events: ["Roi_On_421 오프라인 런칭", "Roi_On_421 웹툰 런칭"],
    },
    {
      year: "2023",
      events: ["421소호 서울 런칭 1호점"],
    },
  ];

  return (
    <MainContainer>
      <div className="animate__animated animate__fadeIn">
        <Header />
        <Section1>
          <MainLogo src={logo_black} alt="GFFG Logo" />
        </Section1>
        <FixedBackgroundSection>
          <ScrollContent>
            <SliderTitle>EVOKING NOSTALGIA</SliderTitle>
            <Description>
              421Co.는 2023년 팝오버 베이크하우스 421소호 런칭 이후 Roi_on_421
              등을 런칭한 Food & Lifestyle 전문 기업입니다. 421Co.는 새로운
              트렌드를 리딩하며 사랑받는 Lifestyle 브랜드 기업으로의 성장을
              목표합니다. '일상 속에 스며든 그리운 시간'을 목표로 한 Food &
              Lifestyle 브랜드를 선보입니다.
            </Description>
          </ScrollContent>
        </FixedBackgroundSection>

        <HistorySection>
          <Title>Our History</Title>
          <Timeline>
            {historyData.map((item, index) => (
              <TimelineItem key={index}>
                <Year>
                  {index === 0 ? (
                    <CircleIcon />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      style={{ marginRight: "10px", fontSize: "20px" }}
                    />
                  )}
                  {item.year}
                </Year>
                <EventList>
                  {item.events.map((event, eventIndex) => (
                    <Event key={eventIndex}>{event}</Event>
                  ))}
                </EventList>
              </TimelineItem>
            ))}
          </Timeline>
        </HistorySection>
        <Footer />
      </div>
    </MainContainer>
  );
}
