import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "lucide-react";
import slide1 from "assets/slide1.png";
import slide2 from "assets/slide2.png";

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
`;

const SliderTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${(props) => `translateX(-${props.currentSlide * 100}%)`};
  align-items: center;
`;

const Slide = styled.div`
  flex: 0 0 100%;
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center;
`;

const SlideImage = styled.img`
  width: 100%;
  max-width: 1200px;
  max-height: 500px;
  object-fit: contain;
  cursor: pointer;
`;

const SlideCaption = styled.p`
  margin-top: 20px;
  padding: 0 20px;
  line-height: 1.6;
  font-size: 0.9rem;
  max-width: 800px;
  text-align: center; /* 중앙 정렬 */
  margin-left: auto;
  margin-right: auto; /* 가로 중앙 배치 */
`;

const SlideDescription = styled.p`
  margin-top: 10px;
  padding: 0 20px;
  line-height: 1.4;
  font-size: 0.8rem;
  color: #888;
  max-width: 800px;
  text-align: center; /* 중앙 정렬 */
  margin-left: auto;
  margin-right: auto; /* 가로 중앙 배치 */
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(217, 218, 204, 0.5);
  color: #000;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background: rgba(217, 218, 204, 0.8);
  }

  ${(props) => (props.direction === "left" ? "left: 10px;" : "right: 10px;")}
`;
export default function LandingPage({ slides }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  //   const slides = [
  //     {
  //       image: slide1,
  //       caption: "421소호 서촌 플래그십스토어",
  //       description:
  //         "2023년 가을 서촌에 오픈한 국내 최초 팝오버 베이크하우스로, 다양한 세이보리/디저트 팝오버와 팝오버 샌드위치, 홈메이드 스프로 사랑받아오고 있습니다. 미국 동부 스테이크하우스 중심으로 오랜 사랑을 받아온 팝오버를 421만의 방식으로 재해석하여 내국인과 함께 많은 외국인들이 방문합니다.",
  //     },
  //     {
  //       image: slide2,
  //       caption: "Roi_on_421",
  //       description:
  //         "2024년 겨울 인스타툰으로서의 연재가 시작된 421Co.의 대표 콘텐츠로, 2023년부터 서촌 플래그십스토어에서 인테리어의 일부이자 굿즈류 제품으로 선보이며 많은 사랑을 받아온 캐릭터 Roi(로이)를 주인공으로 한 IP 콘텐츠입니다. 로이가 421 Broome Street 2층으로 이사오면서 벌어지는 일상에 관한 이야기를 80-90년대 뉴욕 신문 삽화 스타일의 카툰으로 선보입니다.",
  //     },
  //   ];

  const handlePrev = () => {
    setCurrentSlide((current) =>
      current === 0 ? slides.length - 1 : current - 1
    );
  };

  const handleNext = () => {
    setCurrentSlide((current) => (current + 1) % slides.length);
  };

  const handleImageClick = (direction) => {
    if (direction === "left") {
      handlePrev();
    } else if (direction === "right") {
      handleNext();
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((current) => (current + 1) % slides.length);
    }, 4000);

    return () => clearInterval(timer);
  }, [slides.length]);

  return (
    <SliderContainer>
      <SliderTrack currentSlide={currentSlide}>
        {slides.map((slide, index) => (
          <Slide key={index}>
            <SlideImage
              src={slide.image}
              alt={slide.caption}
              onClick={() =>
                handleImageClick(index < currentSlide ? "left" : "right")
              }
            />
            <SlideCaption>{slide.caption}</SlideCaption>
            <SlideDescription>{slide.description}</SlideDescription>
          </Slide>
        ))}
      </SliderTrack>
      <NavButton direction="left" onClick={handlePrev}>
        <ChevronLeft size={24} style={{ color: "gray" }} />
      </NavButton>
      <NavButton direction="right" onClick={handleNext}>
        <ChevronRight size={24} style={{ color: "gray" }} />
      </NavButton>
    </SliderContainer>
  );
}
