import PropTypes from "prop-types";
import React from "react";
import styled from 'styled-components';
import { Button } from "components/atom/Button";

const StyledFormGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 800px;
  position: relative;
`;

const StyledFormControlContainer = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
`;

const StyledFormControl = styled.input`
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 24px;
  justify-content: space-between;
  align-items: center;

  height: 24px;
  border-radius: 90px;
  border: 1px solid #DDD;
  background: #F4F4F4;


  font-family: "Fira Code", monospace;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;

  &::placeholder {
    color: #999;
    font-family: "Fira Code", monospace;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  &:focus {
    outline: none;
    font-family: "Fira Code", monospace;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    box-shadow: none; /* Prevents any shadow on focus */
    border-color: #DDD; /* Keeps border color the same on focus */
  }
`;


// 3632B2
const CustomButton = styled(Button)`
  display: flex;
  width: 112px !important;
  height: 16px !important;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 90px;
  background: #0037FF;
  color: #FFF;
  font-family: "Fira Code", monospace;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

export const TextFieldWithButton = ({ id, placeholder, value, onChange, onButtonClick }) => {
  return (
    <StyledFormGroup>
      <StyledFormControlContainer>
        <StyledFormControl 
          type="text" 
          id={id} 
          placeholder={placeholder} 
          value={value}
          onChange={onChange}
        />
        <CustomButton onClick={onButtonClick}>
          CREATE
        </CustomButton>
      </StyledFormControlContainer>
    </StyledFormGroup>
  );
};

TextFieldWithButton.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};
