"use client";

import React from "react";
import styled from "styled-components";

const SliderTitle = styled.h2`
  font-size: 2rem;
  // margin-bottom: 80px;
  font-weight: 500;
`;

const Description = styled.p`
  max-width: 800px;
  margin: 0 auto 80px;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
`;

const TextArea = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 80px;
  }
`;

export default function Proposal() {
  return (
    <TextArea>
      <SliderTitle>PROPOSAL</SliderTitle>
      <Description>
        421Co.의 브랜드는 패션, 코스메틱, 엔터테인먼트 등 다양한 분야와의 협업을
        진행하고 있으며, 기존 틀을 깨고 새로운 것을 함께 만들 수 있는 모든
        브랜드와의 만남을 기다립니다.
      </Description>
      <Description>421.soho@gmail.com</Description>
    </TextArea>
  );
}
