import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Generate from "components/pages/Generate";
import LandingPage from "components/pages/LandingPage";
import WeAre from "components/pages/WeAre";
import Portfolio from "components/pages/Portfolio";
import Proposal from "components/pages/Proposal";
import Soho from "components/pages/portfolio/421soho";
import Roion421 from "components/pages/portfolio/roion421";
import ScrollRestoration from "./components/scrollRestoration";
import "animate.css";
export const App = () => {
  useEffect(() => {
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <Router>
      <ScrollRestoration />
      <Routes>
        <Route path="/generate" element={<Generate />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/WeAre" element={<WeAre />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Proposal" element={<Proposal />} />
        <Route path="/portfolio/421soho" element={<Soho />} />
        <Route path="/portfolio/roion421" element={<Roion421 />} />
      </Routes>
    </Router>
  );
};

export default App;
