"use client";

import React from "react";
import styled from "styled-components";
import proposal1 from "assets/proposal1.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ProposalSection from "../sections/ProposalSection";
import logo_black from "assets/logo_black.png";
const MainContainer = styled.div`
  background-color: #fff;
  color: #53503f;
`;

const Section1 = styled.section`
  padding: 100px 20px;
  padding-bottom: 10px !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  color: #53503f;
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const FixedBackgroundSection = styled.div`
  background-image: url(${proposal1});
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 100vw;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  @media (max-width: 768px) {
    min-height: 40vh;
  }
`;
const MainLogo = styled.img`
  max-width: 100px;
  margin-bottom: 40px;
`;
export default function Proposal() {
  return (
    <MainContainer>
      <div className="animate__animated animate__fadeIn">
        <Header />
        <Section1>
          <MainLogo src={logo_black} alt="Logo" />
        </Section1>
        <Section1>
          <FixedBackgroundSection></FixedBackgroundSection>
          <ProposalSection />
        </Section1>
        <Footer />
      </div>
    </MainContainer>
  );
}
