// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAJb8j-34-iVryWHKMgly_thg6cjgalbik",
  authDomain: "ai-generator-15ba3.firebaseapp.com",
  projectId: "ai-generator-15ba3",
  storageBucket: "ai-generator-15ba3.appspot.com",
  messagingSenderId: "438998132475",
  appId: "1:438998132475:web:42302660f86403435e725e",
  measurementId: "G-9TQG77PPN2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, "us-central1");

export { functions };