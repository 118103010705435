import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const variants = {
  h1: css`
    font-family: var(--heading-1-font-family);
    font-size: var(--heading-1-font-size);
    font-style: var(--heading-1-font-style);
    font-weight: var(--heading-1-font-weight);
    line-height: var(--heading-1-line-height);
    color: var(--heading-1-color);
    font-feature-settings: 'clig' off, 'liga' off;
  `,
  h2: css`
    font-family: var(--heading-2-font-family);
    font-size: var(--heading-2-font-size);
    font-style: var(--heading-2-font-style);
    font-weight: var(--heading-2-font-weight);
    line-height: var(--heading-2-line-height);
    letter-spacing: var(--heading-2-letter-spacing);
    color: var(--lightbasebase-01);
    font-feature-settings: 'clig' off, 'liga' off;
  `,
  h3: css`
    font-family: var(--heading-3-font-family);
    font-size: var(--heading-3-font-size);
    font-style: var(--heading-3-font-style);
    font-weight: var(--heading-3-font-weight);
    line-height: var(--heading-3-line-height);
    letter-spacing: var(--heading-3-letter-spacing);
    color: var(--lightbasebase-01);
    font-feature-settings: 'clig' off, 'liga' off;
  `,
  body1: css`
    font-family: var(--body-1-font-family);
    font-size: var(--body-1-font-size);
    font-style: var(--body-1-font-style);
    font-weight: var(--body-1-font-weight);
    line-height: var(--body-1-line-height);
    letter-spacing: var(--body-1-letter-spacing);
    text-transform: uppercase;
    color: var(--lightbasebase-01);
    font-feature-settings: 'clig' off, 'liga' off;
  `,
  body2: css`
    font-family: var(--body-2-font-family);
    font-size: var(--body-2-font-size);
    font-style: var(--body-2-font-style);
    font-weight: var(--body-2-font-weight);
    line-height: var(--body-2-line-height);
    letter-spacing: var(--body-2-letter-spacing);
    color: var(--lightbasebase-01);
  `,
};

const StyledTypography = styled.div`
  ${(props) => variants[props.variant]}
`;

export const Typography = ({ variant, children, className }) => {
  return (
    <StyledTypography as="div" variant={variant} className={className}>
      {children}
    </StyledTypography>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'body1', 'body2']).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
