import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { ChevronLeft, ChevronRight } from "lucide-react";
import p1 from "assets/p1.png";
import p2 from "assets/p2.png";
import slide1 from "assets/slide1.png";
import slide2 from "assets/slide2.png";
import logo_black from "assets/logo_black.png";
import ProposalSection from "../sections/ProposalSection";
import SlideSection from "../sections/SlideSection";
const MainContainer = styled.div`
  background-color: #ffffff;
  color: #fff;
`;

const Section1 = styled.section`
  padding: 100px 20px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const MainLogo = styled.img`
  max-width: 100px;
  margin-bottom: 40px;
`;

const Description = styled.p`
  max-width: 800px;
  margin: 0 auto 80px;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  color: #53503f;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;
const Description2 = styled.p`
  max-width: 800px;
  margin: 0 auto 80px;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  color: #53503f;
`;

const BrandGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* 두 개의 사진이 화면을 꽉 채우도록 설정 */
  gap: 20px; /* 간격을 제거하여 이미지가 꽉 차도록 */
  max-width: 800px; /* 중앙에 정렬되도록 최대 너비를 설정 */
  margin: 180px auto; /* 중앙 정렬 */
  margin-bottom: 50px;
  padding: 0 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 모바일에서는 하나씩 표시 */
    gap: 50px;
    justify-items: center;
    margin-bottom: 20px;
    margin-top: 100px;
  }
`;
const ResponsiveImage = styled.img`
  width: 230px !important;

  @media (max-width: 768px) {
    width: 210px !important;
  }
`;
const BrandLogo = styled.a`
  display: block;
  max-width: 350px; /* 최대 너비를 설정 */
  max-height: 350px; /* 최대 높이를 설정 */
  width: 100%;
  height: auto;
  overflow: hidden;
  transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
  &:hover {
    filter: brightness(1);
    transform: scale(1.05);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* 이미지 비율을 유지하며 컨테이너에 맞춤 */
  }
`;

const Section2 = styled.section`
  padding: 50px 20px;
  text-align: center;
  overflow: hidden;
  background-color: #ffffff;
  color: #53503f;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 50px 20px;
    padding-bottom: 0;
  }
`;
const Section3 = styled.section`
  padding: 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  color: #53503f;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;
const SliderTitle = styled.h2`
  font-size: 2rem;
  // margin-bottom: 80px;
  font-weight: 500;
`;
export default function LandingPage() {
  // const [currentSlide, setCurrentSlide] = useState(0);
  const slidesData = [
    {
      image: slide1,
      caption: "421소호 서촌 플래그십스토어",
      description:
        "2023년 가을 서촌에 오픈한 국내 최초 팝오버 베이크하우스로, 다양한 세이보리/디저트 팝오버와 팝오버 샌드위치, 홈메이드 스프로 사랑받아오고 있습니다. 미국 동부 스테이크하우스 중심으로 오랜 사랑을 받아온 팝오버를 421만의 방식으로 재해석하여 내국인과 함께 많은 외국인들이 방문합니다.",
    },
    {
      image: slide2,
      caption: "Roi_on_421",
      description:
        "2024년 겨울 인스타툰으로서의 연재가 시작된 421Co.의 대표 콘텐츠로, 2023년부터 서촌 플래그십스토어에서 인테리어의 일부이자 굿즈류 제품으로 선보이며 많은 사랑을 받아온 캐릭터 Roi(로이)를 주인공으로 한 IP 콘텐츠입니다. 로이가 421 Broome Street 2층으로 이사오면서 벌어지는 일상에 관한 이야기를 80-90년대 뉴욕 신문 삽화 스타일의 카툰으로 선보입니다.",
    },
  ];

  return (
    <MainContainer>
      <Header />
      <div className="animate__animated animate__fadeIn">
        <Section1>
          <MainLogo src={logo_black} alt="GFFG Logo" />
          <Description>
            421컴퍼니(421Co.)는 다양한 문화가 뒤섞여 하나로 동화되는 미국 뉴욕을
            모티브로, 첫 두 개 브랜드의 배경인 ‘421 Broome Street에 있는
            회사’라는 의미를 담고 있습니다.
          </Description>

          <BrandGrid>
            <BrandLogo
              as="a"
              href="/portfolio/421soho" // 이동할 URL
              rel="noopener noreferrer" // 보안 설정
            >
              <ResponsiveImage src={p1} alt="DOWNTOWNER" />
            </BrandLogo>
            <BrandLogo
              as="a"
              href="/portfolio/roion421" // 이동할 URL
              rel="noopener noreferrer" // 보안 설정
            >
              <img src={p2} alt="DOWNTOWNER" />
            </BrandLogo>
          </BrandGrid>
          <Description>
            * 로고를 클릭하면 각 브랜드 소개를 보실 수 있습니다.
          </Description>
        </Section1>

        <Section2>
          <SliderTitle>NOSTALGIA : REMEMBER THE MOMENTS</SliderTitle>
          <Description2>
            그 때 그 곳, 그 시간에서의 기억을 떠올릴 수 있게 - 421Co.의
            브랜드들이 공통적으로 관통하는 철학입니다. 고객분들이 일상 속 향수
            어린 여유를 경험하실 수 있도록 익숙하면서도 새로운 브랜드 경험을
            만들어 나갑니다.
          </Description2>
          <SlideSection slides={slidesData} />
        </Section2>
        <Section3>
          <ProposalSection />
        </Section3>

        <Footer />
      </div>
    </MainContainer>
  );
}
