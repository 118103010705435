import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import loadingProgress from "assets/loading-progress.svg";

// Define the rotation animation with a slower speed
const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Apply the animation, fill, and dimensions to the SVG image
const RotatingSVG = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background-image: conic-gradient(from 180deg at 50% 50%, rgba(0, 55, 255, 0.0) 0deg, #0037FF 360deg);
  mask-image: url(${loadingProgress}); // Use the SVG as a mask
  mask-size: cover;
  animation: ${rotateAnimation} 2s linear infinite;
`;

export const LoadingProgress = ({ className }) => (
  <RotatingSVG className={className} />
);

LoadingProgress.propTypes = {
  className: PropTypes.string,
};
