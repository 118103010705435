import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Typography } from "components/atom/Typography";

const StyledButton = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 0; /* padding 제거 */
  height: 40px; /* 높이를 명시적으로 설정 */
  width: 100%; /* 버튼의 전체 너비를 채우도록 설정 */

  & .flex-item {
    flex: 1 !important;
    flex-grow: 1 !important;
    height: 21px !important;
    width: unset !important;
  }

  & .body-2 {
    align-self: stretch !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    height: unset !important;
    width: unset !important;
  }

  & .active-text {
    color: var(--lightaccentsblue-02) !important;
    text-align: center !important;
    top: 2px !important;
    width: 71px !important;
  }

  & .deactive-text {
    color: var(--lightbasebase-03) !important;
    text-align: center !important;
    top: -2px !important;
    width: 71px !important;
  }

  & .default-text {
    text-align: center !important;
    top: 2px !important;
    width: 71px !important;
  }

  & .small-active-text {
    color: var(--lightaccentsblue-02) !important;
    text-align: center !important;
    width: 56px !important;
  }

  & .small-deactive-text {
    color: var(--lightbasebase-03) !important;
    text-align: center !important;
    width: 56px !important;
  }

  & .small-default-text {
    color: var(--lightbasebase-05) !important;
    text-align: center !important;
    width: 56px !important;
  }

  & .medium-text {
    text-transform: uppercase;
    color: ${(props) => (props.status === 'filled' ? 'var(--lightbasebase-05)' : 'inherit')} !important;
    text-align: center;
  }

  &.small {
    align-items: center;
    justify-content: center;
    height: 29px;
    width: 72px;
  }

  &.active {
    align-items: flex-start;
    border: 1px solid;
    border-color: var(--lightaccentsblue-03);
  }

  &.medium {
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 71px;
  }

  &.filled {
    background-color: var(--lightaccentsblue-02);
  }

  &.deactive {
    border: 1px solid;
    border-color: var(--lightbasebase-03);
    pointer-events: none;
  }

  &.deactive.medium {
    align-items: center;
    justify-content: center;
  }
`;

export const Button = ({ status = "default", size = "small", className, children, onClick, disabled }) => {
  return (
    <StyledButton className={`button ${size} ${status} ${className}`} status={status} onClick={onClick} disabled={disabled}>
      <Typography
        variant={size === "medium" ? "body1" : "body2"}
        className={`${size === "medium" ? "medium-text" : `${status === "active" ? "small-active-text" : status === "deactive" ? "small-deactive-text" : "small-default-text"}`}`}
        style={{ lineHeight: "1", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {children}
      </Typography>
    </StyledButton>
  );
};

Button.propTypes = {
  status: PropTypes.oneOf(["deactive", "filled", "active", "default"]),
  size: PropTypes.oneOf(["medium", "small"]),
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
