"use client";

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "../layout/header";
import Footer from "../layout/footer";
import logo_black from "assets/logo_black.png";
import p1 from "assets/p1.png";
import p2 from "assets/p2.png";

const MainContainer = styled.div`
  background-color: #ffffff;
  color: #000;
`;

const Section1 = styled.section`
  padding: 100px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
  @media (max-width: 768px) {
    padding: 50px 20px;
    padding-bottom: 0;
  }
`;

const Description = styled.p`
  max-width: 800px;
  margin: 50px auto 60px;
  line-height: 1.6;
  font-size: 1rem;
  color: #53503f;
`;

const Description2 = styled.p`
  max-width: 800px;
  margin: 20px auto 40px;
  line-height: 1.6;
  font-size: 1rem;
  color: #53503f;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const MainLogo = styled.img`
  max-width: 100px;
  margin-bottom: 40px;
`;

const BrandGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 800px;
  margin: 50px auto;
  margin-bottom: 30px !important;
  padding: 0 20px;
  gap: 10px;
  align-items: center;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 50px;
    margin-bottom: 0px !important;
  }
`;

const BrandLogo = styled.img`
  width: ${(props) => (props.isFirst ? "250px" : "100%")};
  max-width: 350px; /* 최대 너비를 설정 */
  max-height: 350px; /* 최대 높이를 설정 */
  height: auto;
  cursor: pointer;
  transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    filter: brightness(1);
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    width: ${(props) => (props.isFirst ? "200px" : "100%")};
  }
`;
const DynamicSection = styled.section`
  max-width: 760px;
  margin: 40px auto;
  padding: 20px;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  min-height: 350px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const ContentWrapper = styled.div`
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 40px !important;
  @media (max-width: 768px) {
    padding-bottom: 70px !important;
    padding-bottom: 70px !important;
  }
`;

const DynamicTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const DynamicDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
`;

const MoreLink = styled.a`
  display: inline-block;
  margin-top: 30px;
  color: #53503f;
  text-decoration: none;
  font-weight: bold;
  float: right;
  &:hover {
    text-decoration: underline;
  }
`;

export default function Portfolio() {
  const [activeContent, setActiveContent] = useState(null);
  const [isContentVisible, setIsContentVisible] = useState(true);

  const toggleContent = (contentId) => {
    if (activeContent === contentId) {
      setActiveContent(null);
    } else {
      setIsContentVisible(false);
      setTimeout(() => {
        setActiveContent(contentId);
        setIsContentVisible(true);
      }, 300);
    }
  };

  useEffect(() => {
    // Set the first brand as active on initial load
    toggleContent("brand1");
  }, []);

  const brandContents = {
    brand1: {
      title: "421소호",
      description:
        "팝오버 베이크하우스 '421소호'는 각 다른 문화 출신 이민자가 세들어 살고 있는 421 Broome Street 하우스의 각 층을 모티브 삼는 F&B 브랜드입니다. 우리는 다양한 문화의 맛과 경험을 한 곳에서 즐길 수 있는 유니크한 공간을 제공합니다.",
      link: "/portfolio/421soho",
    },
    brand2: {
      title: "Roi_on_421",
      description:
        "'Roi_on_421'는 421 Broome Street 2층으로 이사오게 된 Roi 와 Pip의 모험천만한 일상 이야기를 담은 카툰 브랜드입니다. 우리는 이 캐릭터들을 통해 현대 도시 생활의 즐거움과 도전을 유머러스하게 표현합니다.",
      link: "/portfolio/roion421",
    },
  };

  return (
    <MainContainer>
      <div className="animate__animated animate__fadeIn">
        <Header />
        <Section1>
          <MainLogo src={logo_black} alt="GFFG Logo" />
          <Description>
            421Co.의 브랜드들은 유기적으로 연결되어 있습니다.
          </Description>

          <BrandGrid>
            <BrandLogo
              src={p1}
              alt="sogo"
              onClick={() => toggleContent("brand1")}
              isFirst
            />
            <BrandLogo
              src={p2}
              alt="roion"
              onClick={() => toggleContent("brand2")}
            />
          </BrandGrid>
          <Description2>
            * 로고를 클릭하면 각 브랜드 소개를 보실 수 있습니다.
          </Description2>
        </Section1>
        <DynamicSection isVisible={activeContent !== null}>
          {activeContent && (
            <ContentWrapper isContentVisible={isContentVisible}>
              <DynamicTitle>{brandContents[activeContent].title}</DynamicTitle>
              <DynamicDescription>
                {brandContents[activeContent].description}{" "}
                <MoreLink href={brandContents[activeContent].link}>
                  더보기 &gt;
                </MoreLink>
              </DynamicDescription>
            </ContentWrapper>
          )}
          <Description2>
            * 더보기를 클릭하면 각 브랜드의 상세페이지로 이동합니다.
          </Description2>
        </DynamicSection>

        <Footer />
      </div>
    </MainContainer>
  );
}
